import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AppState } from './../../app.service';
import { AppVars } from './../../app.vars';

const SEND_SMS = 'sendSMS';
@Injectable({ providedIn: 'root' })
export class SMSService {
  constructor(private afn: AngularFireFunctions, private _as: AppState) {}

  async sendSMS(clientId: string, phone: string, message: string): Promise<any> {
    // check for valid phone number
    if (!this.isValidPhoneNumber(phone)) {
      return Promise.reject('Invalid phone number!');
    }
    const sendSMS = this.afn.httpsCallable(SEND_SMS);
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const payload = { orgCode, clientId, phone, message };
    console.log('[SMSService] sendSMS():', payload);
    const orgMetaData = this._as.get(AppVars.ORG_METADATA);
    const canSendSMS = orgMetaData['notification-config'].sendSMS;
    if (!canSendSMS) {
      return Promise.reject('SMS notifications are not enabled! Please contact support.');
    }
    // Use the sendSMS cloud function to send a SMS notification message to the client
    try {
      const result = await sendSMS(payload).toPromise();
      // const result = { status: true, error: null };
      if (result && result.status) {
        console.log('[SMSService] sendSMS():', result);
        return Promise.resolve();
      } else {
        console.log('ERROR! [SMSService] sendSMS():', result?.error);
        return Promise.reject(result.error);
      }
    } catch (error) {
      console.log('ERROR! [SMSService] sendSMS():', error);
      return Promise.reject(error);
    }
  }

  // Check for a valid phone number
  isValidPhoneNumber(phoneNumber: string): boolean {
    // Should contain only 10 digits & after area code there should not be 555
    const regex = /^\d{3}(?!555)\d{7}$/;

    // console.log('[PatientDataService] Phone No. Test:', phoneNumber, regex.test(phoneNumber), regex1.test(phoneNumber));
    return regex.test(phoneNumber);
  }
}
