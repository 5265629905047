import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from '../../auth';
import { AppointmentEvent } from '../appointment-notification/appointment-event';
import { AppointmentNotificationService } from '../appointment-notification/appointment-notification.service';
import { PatientLoggerService } from '../patient-data/patient-note.service';
import { PerfMonitorService } from '../perf-monitor.service';
import { AppState } from './../../../app.service';
import { AppVars } from './../../../app.vars';
import { SMSService } from './../../../pages/send-sms/sms.service';
import { Patient, PatientDataEvents } from './../patient-data/patient';
import { Appointment, AppointmentStatus, AppointmentType } from './appointment';
import { NameUtil } from '@mlc/shared/utils/name-util';

interface MailMessage {
  from: string;
  to: string;
  cc?: string;
  subject: string;
  content: string;
}

interface Payload {
  orgCode: string;
  clientId: string;
  message: MailMessage;
}

// Named Cloud functions
const SEND_EMAIL = 'sendEmail';

@Injectable()
export class AppointmentDataService {
  // checkedInPatients: Observable<any[]>;
  // checkedInPatientsCount: Observable<number>;

  // private orgCode: string;
  // private basePath: string;
  // private providersPath: string;
  // private facilitiesPath: string;
  // private patientsPath: string;
  // private patientAppointmentsPath: string;
  // private appointmentsPath: string;
  // private appointmentTypesPath: string;
  perfTraceOptions: { attributes: { orgCode: string } };
  private orgData: { name: string; contactPhone: string; [key: string]: any };
  private appointmentTypes!: AppointmentType[];
  // TODO: Review Performance Monitoring and fix

  constructor(
    private ngDb: AngularFireDatabase,
    private _as: AppState,
    private auth: AuthService,
    private smsService: SMSService,
    private afn: AngularFireFunctions,
    private pns: PatientLoggerService,
    private perf: PerfMonitorService,
    private ans: AppointmentNotificationService
  ) {
    this.auth
      .getOrgData(this._as.get(AppVars.ORG_CODE))
      .pipe(take(1))
      .subscribe((orgData) => {
        this.orgData = orgData;
      });
  }

  /**
   * Providers List - available to attend patients in the provider org
   */
  public getProvidersList(): Observable<any[]> {
    // PRAPP-146: Ensure only enabled Provider users are returned and filter out any disabled PMD user
    const user = this._as.get(AppVars.USER_ACCOUNT);
    return this.ngDb
      .list(this.getProvidersPath())
      .valueChanges()
      .pipe(
        map((providers: any[]) => {
          // console.log(providers);
          let validProviders = providers.filter((provider) => provider.approved !== false);
          if (user.roles?.pmd) {
            // PRN-42: List the only provider, if logged in user as PMD
            validProviders = validProviders.filter((provider) => {
              const name = provider.name || provider.displayName;
              return name === user.displayName;
            });
          }
          // sort the providers by name
          validProviders = validProviders
            .map((p) => ({ ...p, formattedName: NameUtil.getFormattedDoctorName(p.name || p.displayName), name: p.name || p.displayName }))
            .sort((a, b) => (a.formattedName < b.formattedName ? -1 : 1));
          return validProviders;
        }),
        shareReplay()
      );
  }

  /**
   * Appointment Export Data Headers List
   */
  public getAppointmentHeaders(): Observable<string[]> {
    return this.ngDb.list<string>(this.getAppointmentHeadersPath()).valueChanges().pipe(shareReplay());
  }

  /**
   * Calendar Window start & end time
   */
  public getCalendarWindow(): Observable<any> {
    return this.ngDb.object<any>(this.getCalendarWindowPath()).valueChanges().pipe(shareReplay());
  }

  public getAllFacilities(): Observable<any[]> {
    return this.ngDb.list(this.getFacilitiesPath()).valueChanges().pipe(shareReplay());
  }

  /**
   * Facilities List - available to host patients in the provider org
   */
  public getFacilitiesList(): Observable<any[]> {
    return this.ngDb
      .list(this.getFacilitiesPath())
      .valueChanges()
      .pipe(
        map((facilities: any) => {
          return facilities.map((facility: any) => facility.name);
        }),
        map((facilities: string[]) => facilities.sort()),
        shareReplay(1)
      );
  }

  /**
   * Pre-configured List of Appointment Types for the provider org
   */
  public getAppointmentTypes(): Observable<any[]> {
    return this.ngDb
      .list(this.getAppointmentTypesPath())
      .valueChanges()
      .pipe(
        tap((types: AppointmentType[]) => {
          this.appointmentTypes = types;
        }),
        shareReplay()
      );
  }

  /**
   * Pre-configured List of Appointment Slots for the provider org
   */
  public getAppointmentSlots(): Observable<any[]> {
    return this.ngDb
      .list(this.getAppointmentSlotsPath())
      .valueChanges()
      .pipe(
        map((slots: any[]) => slots.sort((a, b) => (a.duration < b.duration ? -1 : 1))),
        shareReplay(1)
      );
  }

  /**
   * Appointments List - for the desired (optional) date range
   * @param startDate Date from which the appointments to be listed - default current date
   * @param endDate Date to which the appointments to be listed - default current date
   * @return Observable<any[]>: Array of Appointment Objects
   */
  public getAppointments(startDate?: Date, endDate?: Date): Observable<Appointment[] | any[]> {
    const today = startDate ? startDate : new Date();
    const startAt = new Date(today);
    startAt.setHours(0, 0, 0, 0);
    const endAt = endDate ? endDate : new Date(today);
    endAt.setHours(23, 59, 59);

    const queryObj = {
      orderByChild: 'start',
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
    };
    console.log('[AppointmentDataService] - Query Obj:', queryObj);
    const appointments$ = this.ngDb
      .list(this.getAppointmentsPath(), (ref) => ref.orderByChild('start').startAt(startAt.toISOString()).endAt(endAt.toISOString()))
      .snapshotChanges()
      .pipe(
        // this.perf.setTrace('appointmentsList', this.perfTraceOptions),
        map((appointments: any[]) => appointments.map((appointment) => ({ id: appointment.key, ...appointment.payload.val() }))),
        shareReplay(1)
      );

    const user = this._as.get(AppVars.USER_ACCOUNT);
    const isPMD = user.roles.pmd && !user.roles.fda && !user.roles.adm;
    return isPMD ? appointments$.pipe(map((appointments) => appointments.filter((appt) => appt.with === user.displayName))) : appointments$;
  }

  /**
   * All appointments list from the base appointments path for the org
   * Use this sparingly, this can result in the Observable which is not paginated
   */
  public getAllAppointments(): Observable<any[]> {
    return this.ngDb.list(this.getAppointmentsPath()).valueChanges();
  }

  /**
   * Get all appointments for the specific patient
   * Observable returned has appointments in ascending order of Appointment Start date-time
   * @param patientKey - Key or id of the patient object
   * @return Observable<any> - Observable yielding the list of appointments for the patient
   */
  public getPatientAppointments(patientKey: string): Observable<any[]> {
    const patientAppointmentPath = `${this.getPatientAppointmentsPath()}/${patientKey}`;
    // Order appointments by start time (default is ascending), caller can reverse the outcome to arrange in descending order
    return this.ngDb
      .list(patientAppointmentPath, (ref) => ref.orderByChild('start'))
      .snapshotChanges()
      .pipe(map((appointments: any[]) => appointments.map((appointment) => ({ id: appointment.key, ...appointment.payload.val() }))));
  }

  // Used for QME Form - to get the patient's first 'valid' appointment
  // ensure that it is not rescheduled
  public getPatientFirstAppointment(patientKey: string): Observable<any[]> {
    const patientAppointmentPath = `${this.getPatientAppointmentsPath()}/${patientKey}`;
    // Order appointments by start time (default is ascending), caller can reverse the outcome to arrange in descending order
    return this.ngDb
      .list(patientAppointmentPath, (ref) => ref.orderByChild('start').limitToFirst(30))
      .valueChanges()
      .pipe(
        map((appointments: Appointment[]) => {
          return appointments.filter((appt) => appt.statusId < AppointmentStatus.NO_SHOW);
        })
      );
  }

  // Used for QME Form
  public getFacility(facilityName: string): Observable<any[]> {
    return this.ngDb
      .list(this.getFacilitiesPath(), (ref) => ref.orderByChild('name').equalTo(facilityName))
      .valueChanges()
      .pipe(shareReplay(1));
  }

  // TODO: Define an Appointment interface
  /**
   * Add a new Appointment for the patient account under the provider org
   * @param patient - Patient object
   * @param appointmentDate - Start date & time (Date object) of the appointment
   * @param appointmentDetails - Details of the appointment
   * @param appointmentWith - Consulting Provider with whom the Patient has the appointment
   * @param appointmentFacility - Facility at which the Provider would be attending
   * @return Promise<any>
   */
  public async addNewAppointment(
    // TODO: Refactor the method signature, only take an Appointment Object and Patient Id
    patient: Patient,
    patientKey,
    appointmentDate,
    appointmentDetails?: string,
    appointmentWith?: string,
    appointmentFacility?: string,
    appointmentDuration?: number,
    appointmentType?: AppointmentType,
    appointmentNotes?: string,
    appointmentWithSuffix?: string
  ) {
    const user = this._as.get(AppVars.USER_ACCOUNT);
    console.log('ADS: Appointment Date: ', appointmentDate, user.displayName);

    const appointmentObj = {
      start: appointmentDate,
      email: patient.email || null,
      details: appointmentDetails ? appointmentDetails : appointmentType ? appointmentType.name : null,
      type: appointmentType ? appointmentType.shortCode : null,
      statusId: AppointmentStatus.SCHEDULED,
      with: appointmentWith,
      withSuffix: appointmentWithSuffix ? appointmentWithSuffix : null,
      facility: appointmentFacility ? appointmentFacility : null,
      duration: appointmentDuration ? appointmentDuration : 30,
      notes: appointmentNotes ? appointmentNotes : null,
      scheduledBy: user.displayName || user.email,
      // Calendar just adds 'name' and not patient object
      name: patient.name || `${patient.firstName} ${patient.lastName}`,
      updateTime: new Date().toISOString(),
      patientKey: patientKey, // TODO: Why the patientKey is not available from the Patient object?
    };

    // Update the latest appointment to the patient node (in provider's path) as 'nextAppointment'- done by serverless-functions
    // Note: now the 'addAppointmentId' serverless-functions takes care of pushing the id to the new node as well as duplicating
    // the appointment object into the patient-appointments/{rehashedEmail} path as well (Updated on: 19 Feb 2018)

    // Add this appointment to the provider's appointments List
    await this.ngDb.list(this.getAppointmentsPath()).push(appointmentObj);
    // Add Appointment Scheduled note
    this.pns.patientLogger$.next({
      patientKey,
      event: PatientDataEvents.APPOINTMENT_SCHEDULED,
    });

    this.getFacilityDetails(appointmentObj.facility)
      .pipe(take(1))
      .subscribe((data) => {
        const apptDetails = {
          ...appointmentObj,
          ...data,
        };
        console.log('[AppointmentDataService] addNewAppointment(): apptDetails =', apptDetails);
        // Send new appointment notification if appointment type doesn't block notifications
        // if (!this.isAppointmentNotificationBlocked(appointmentObj)) {
        this.ans.apptNotification$.next({
          appointment: apptDetails,
          event: AppointmentEvent.APPOINTMENT_SCHEDULED,
          blockedNotification: this.isAppointmentNotificationBlocked(appointmentObj),
        });
        // }
      });

    // Send SMS to client about the new appointment using the following template
    const orgPhone = this.phoneFormat(this.orgData.contactPhone);
    const message = `
    Reminder: You are scheduled for an appointment at ${this.orgData.name}
     on ${new Date(appointmentDate).toLocaleDateString()} at
      ${new Date(appointmentDate).toTimeString()}.
     Please arrive 5 mins early for the appointment. If you cannot make the appointment,
     please call our office at ${orgPhone} and we will reschedule your appointment.
    `;
    const phone = `+1${patient.cellPhone}`;
    // await this.smsService.sendSMS(patientKey, phone, message);
    return;
  }

  /**
   * Update the appointment. The appointmentObj is structured with Key and Appointment as two fields
   * @param appointmentObj Appointment object to be updated
   */
  public async updateAppointment(appointmentObj: any): Promise<any> {
    const updateAppointmentObj = { ...appointmentObj };
    updateAppointmentObj.updateTime = new Date().toISOString();
    updateAppointmentObj['updatedBy'] = this._as.get(AppVars.USER_ACCOUNT).displayName;
    console.log('[AppointmentDataService] updateAppointment(): appointment = ', updateAppointmentObj);
    // Appointment Updated transactionlog.
    this.pns.patientLogger$.next({
      patientKey: updateAppointmentObj.patientKey,
      event: PatientDataEvents.APPOINTMENT_UPDATED,
    });

    // Update this appointment in the provider's appointments List
    return this.ngDb.list(this.getAppointmentsPath()).update(updateAppointmentObj.id, updateAppointmentObj);
    // TODO: Send SMS to patient about appointment update
    // const orgPhone = this.phoneFormat(this.orgData.contactPhone);
    // const message = `
    // Hello ${patient.firstName},
    //  ${this.orgData.name}} has updated the schedule of the appointment for you
    //   on ${new Date(appointmentDate).toLocaleDateString()} at ${new Date(appointmentDate).toTimeString()}.
    //   If you can't make this appointment, please call our office
    //    ${orgPhone}} and we will schedule you a time that works. We are here to help.
    // `;
    // const phone = `+1${patient.cellPhone}`;
    // return this.smsService.sendSMS(patientKey, phone, message);
  }

  /**
   * Set / Update the Status of the patient appointment
   * @param appointmentKey - Database key of the specific appointment node
   * @param status - status ID enumeration value (0, 1, 2, 3, 4, 5, 6) from AppointmentStatus
   */
  public setAppointmentStatus(appointment: any, status: AppointmentStatus): Promise<any> {
    if (!appointment || !appointment.id) {
      throw new Error('Cannot update Appointment Status, Appointment Key is missing!');
    }
    const updatePath = `${this.getAppointmentsPath()}/${appointment.id}`;
    const updateObj = {
      statusId: status,
      updateTime: new Date().toISOString(),
    };
    const patientKey = appointment.patientKey;
    const event = this.getAppointmentEvent(status);
    // TODO: Cleanup and improve this code
    if (status === AppointmentStatus.CHECKED_IN) {
      updateObj['checkIn'] = new Date().toISOString();
    }
    // Add appointment update log
    this.pns.patientLogger$.next({ patientKey, event });

    if (status === AppointmentStatus.CANCELLED || status === AppointmentStatus.NO_SHOW) {
      this.getFacilityDetails(appointment.facility).subscribe((data) => {
        const apptDetails = {
          ...appointment,
          ...data,
        };
        // Send new appointment notification if appointment type doesn't block notifications
        // if (!this.isAppointmentNotificationBlocked(appointment)) {
        this.ans.apptNotification$.next({
          appointment: apptDetails,
          event: this.getAppointmentNotificationEvent(status),
          blockedNotification: this.isAppointmentNotificationBlocked(appointment),
        });
        // }
      });
    }
    return this.ngDb.object(updatePath).update(updateObj);
  }

  // Get Late Cancellation Policy {enabled: boolean, days: number}
  public getLateCancellationPolicy(): Observable<any> {
    return this.ngDb.object<any>(this.getLateCancellationPolicyPath()).valueChanges().pipe(shareReplay());
  }

  /**
   * Cancel the Appointment or Mark the appointment as No Show with staff follow-up
   * @param appointmentObj - Appointment object which should be updated as cancelled
   * @param callbackDueDate - Callback due date for Staff follow-up
   * @param status - Appointment Status (optional) default is CANCELLED
   */
  public cancelAppointment(appointmentObj: any, callbackDueDate?: Date, status: number = AppointmentStatus.CANCELLED): Promise<any> {
    const path = `${this.getAppointmentsPath()}/${appointmentObj.id}`;
    // Statuses: 0 - Scheduled, 1- Checked-In, 2 - Completed, 3 - No Show, 4 - Cancelled, 5 - Re-Scheduled, 6 - Late Cancelled
    const updateObj = {
      statusId: status,
      updateTime: new Date().toISOString(),
      staffCallback: callbackDueDate ? callbackDueDate.toISOString() : null,
    };
    console.log('[PatientDataService] cancelAppointment(): updateObj = ', updateObj);
    // Update the status in /appointments path
    return this.ngDb
      .object(path)
      .update(updateObj)
      .then(() => {
        // Now update the patient object nextAppointment property
        const pPath = `${this.getPatientsPath()}/${appointmentObj.patientKey}`;
        const uObj = {
          // TODO: Move this to firebase functions
          callbackReason: status === AppointmentStatus.CANCELLED ? 'CANCELLED' : 'NO SHOW',
          staffCallback: callbackDueDate ? callbackDueDate.toISOString() : null,
        };
        // Add Appointment Cancelled event log
        this.pns.patientLogger$.next({
          patientKey: appointmentObj.patientKey,
          event: status === AppointmentStatus.CANCELLED ? PatientDataEvents.APPOINTMENT_CANCELLED : PatientDataEvents.APPOINTMENT_NOSHOW,
        });
        this.getFacilityDetails(appointmentObj.facility)
          .pipe(take(1))
          .subscribe((data) => {
            const apptDetails = {
              ...appointmentObj,
              ...data,
            };
            // Send new appointment notification if appointment type doesn't block notifications
            if (!this.isAppointmentNotificationBlocked(appointmentObj)) {
              this.ans.apptNotification$.next({
                appointment: apptDetails,
                event:
                  status === AppointmentStatus.CANCELLED ? AppointmentEvent.APPOINTMENT_CANCELLED : AppointmentEvent.APPOINTMENT_NOSHOW,
                blockedNotification: this.isAppointmentNotificationBlocked(appointmentObj),
              });
            }
          });

        this.ngDb
          .object(pPath)
          .update(uObj)
          .catch((err) => {
            throw new Error(err.message);
          });
      })
      .catch((err) => {
        throw new Error(err.message);
      });
    // TODO: Send SMS to patient about appointment cancellation
  }

  /**
   * Late Cancel the Appointment
   * @param appointmentObj - Appointment object which should be updated as cancelled
   * @param status - Appointment Status (optional) default is LATE CANCELLED
   */
  public lateCancelAppointment(appointmentObj: any, status: number = AppointmentStatus.LATE_CANCELLED): Promise<any> {
    const path = `${this.getAppointmentsPath()}/${appointmentObj.id}`;
    // Statuses: 0 - Scheduled, 1- Checked-In, 2 - Completed, 3 - No Show, 4 - Cancelled, 5 - Re-Scheduled, 6 - Late Cancelled
    const updateObj = {
      statusId: status,
      updateTime: new Date().toISOString(),
    };
    console.log('[PatientDataService] lateCancelAppointment(): updateObj = ', updateObj);
    // Update the status in /appointments path
    return this.ngDb
      .object(path)
      .update(updateObj)
      .then(() => {
        // Add Appointment Cancelled event log
        this.pns.patientLogger$.next({
          patientKey: appointmentObj.patientKey,
          event: PatientDataEvents.APPOINTMENT_LATE_CANCELLED,
        });
        this.getFacilityDetails(appointmentObj.facility)
          .pipe(take(1))
          .subscribe((data) => {
            const apptDetails = {
              ...appointmentObj,
              ...data,
            };
            // Send new appointment notification if appointment type doesn't block notifications
            if (!this.isAppointmentNotificationBlocked(appointmentObj)) {
              this.ans.apptNotification$.next({
                appointment: apptDetails,
                event: AppointmentEvent.APPOINTMENT_CANCELLED,
                blockedNotification: this.isAppointmentNotificationBlocked(appointmentObj),
              });
            }
          });
      })
      .catch((err) => {
        throw new Error(err.message);
      });
    // TODO: Send SMS to patient about appointment cancellation
  }

  /**
   * Get the list of Patient Appointments with Status as Checked-In
   */
  public getCheckedInPatientAppointments(): Observable<any[]> {
    const user = this._as.get(AppVars.USER_ACCOUNT);

    return this.ngDb
      .list(this.getAppointmentsPath(), (ref) => ref.orderByChild('statusId').equalTo(AppointmentStatus.CHECKED_IN))
      .snapshotChanges()
      .pipe(
        // TODO: Performance Tracing Checked In Patient List
        // this.perf.setTrace('checkedInPatients', {
        //   attributes: { orgCode: this._as.get(AppVars.ORG_CODE) },
        // }),
        map((appointments: any[]) => appointments.map((appointment) => ({ id: appointment.key, ...appointment.payload.val() }))),
        map((appointments: any[]) => appointments.filter((appointment) => Boolean(appointment.start))),
        map((appointments: any[]) =>
          user.roles?.pmd ? appointments.filter((appointment) => appointment.with === user.displayName) : appointments
        ),
        shareReplay()
      );
  }

  /**
   * Get the count of Checked In Patients
   */
  public getCheckedInPatientsCount(): Observable<number> {
    return this.ngDb
      .list(this.getAppointmentsPath(), (ref) => ref.orderByChild('statusId').equalTo(AppointmentStatus.CHECKED_IN))
      .valueChanges()
      .pipe(
        map((p) => p.length),
        shareReplay()
      );
  }

  // TODO: Refactor to simplify these methods leveraging a common Path Service
  // this.orgCode = this._as.get(AppVars.ORG_CODE);
  //   this.basePath = `provider-org-${this.orgCode}`;
  //   this.providersPath = `${this.basePath}/providers`;
  //   this.facilitiesPath = `${this.basePath}/facilities`;
  //   this.patientsPath = `${this.basePath}/patients`;
  //   this.appointmentsPath = `${this.basePath}/appointments`;
  //   this.appointmentTypesPath = `${this.basePath}/appointment-types`;
  //   this.patientAppointmentsPath = `${this.basePath}/patient-appointments`;

  private getBasePath(): string {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return `provider-org-${orgCode}`;
  }

  private getProvidersPath(): string {
    return `${this.getBasePath()}/providers`;
  }

  private getFacilitiesPath(): string {
    return `${this.getBasePath()}/facilities`;
  }
  private getPatientsPath(): string {
    return `${this.getBasePath()}/patients`;
  }
  private getAppointmentsPath(): string {
    return `${this.getBasePath()}/appointments`;
  }
  private getAppointmentTypesPath(): string {
    return `${this.getBasePath()}/appointment-types`;
  }
  private getAppointmentSlotsPath(): string {
    return `${this.getBasePath()}/appointment-slots`;
  }
  private getAppointmentHeadersPath(): string {
    return `${this.getBasePath()}/appointment-headers`;
  }
  private getCalendarWindowPath(): string {
    return `${this.getBasePath()}/appointment-calendar-window`;
  }

  private getLateCancellationPolicyPath(): string {
    return `${this.getBasePath()}/late-cancellation-policy`;
  }
  private getPatientAppointmentsPath(): string {
    return `${this.getBasePath()}/patient-appointments`;
  }

  private phoneFormat(input) {
    if (!input) {
      return '-';
    }
    if (typeof input !== 'string') {
      input = input.toString();
    }
    input = input.replace(/\D/g, '');
    if (input.length === 10) {
      return input.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else {
      return '-';
    }
  }

  private getAppointmentEvent(status: AppointmentStatus): PatientDataEvents {
    switch (status) {
      case AppointmentStatus.CANCELLED:
        return PatientDataEvents.APPOINTMENT_CANCELLED;
      case AppointmentStatus.LATE_CANCELLED:
        return PatientDataEvents.APPOINTMENT_LATE_CANCELLED;
      case AppointmentStatus.CHECKED_IN:
        return PatientDataEvents.APPOINTMENT_CHECKED_IN;
      case AppointmentStatus.COMPLETED:
        return PatientDataEvents.APPOINTMENT_COMPLETED;
      case AppointmentStatus.NO_SHOW:
        return PatientDataEvents.APPOINTMENT_NOSHOW;
      case AppointmentStatus.RE_SCHEDULED:
        return PatientDataEvents.APPOINTMENT_RESCHEDULED;

      default:
        return PatientDataEvents.APPOINTMENT_SCHEDULED;
    }
  }

  private getAppointmentNotificationEvent(status: AppointmentStatus): AppointmentEvent {
    console.log('Appointment data service getAppointmentNotificationEvent():', status);
    switch (status) {
      case AppointmentStatus.CANCELLED:
        return AppointmentEvent.APPOINTMENT_CANCELLED;
        break;
      case AppointmentStatus.NO_SHOW:
        return AppointmentEvent.APPOINTMENT_NOSHOW;
        break;
      case AppointmentStatus.RE_SCHEDULED:
        return AppointmentEvent.APPOINTMENT_RESCHEDULED;
        break;
      default:
        return AppointmentEvent.APPOINTMENT_SCHEDULED;
        break;
    }
  }

  // Check for the appointment if appointment type blocks notifications
  private isAppointmentNotificationBlocked(appointment: any): boolean {
    const metadata = this._as.get(AppVars.ORG_METADATA);
    if (!metadata || metadata['notification-config'].blockAppointmentNotification) {
      return true;
    }
    if (!appointment || !appointment.type) {
      return false;
    }
    const appointmentType = this.appointmentTypes.find((t) => t.shortCode === appointment.type);
    return Boolean(appointmentType?.blockNotification);
  }

  private getFacilityDetails(facilityName: string): Observable<any> {
    let facilityData: any = '';
    return this.getFacility(facilityName).pipe(
      switchMap((data) => {
        if (data && data.length) {
          facilityData = data[0];
          const facilityAddress =
            (facilityData.address ? `${facilityData.address}, ` : '') +
            (facilityData.city ? `${facilityData.city}, ` : '') +
            (facilityData.state ? `${facilityData.state} ` : '') +
            (facilityData.zip ? ` - ${this.formatFacilityZipCode(facilityData.zip)}` : '');
          const facilityDetails = {
            facility: facilityData.name,
            facilityAddress,
            facilityPhone: facilityData.primaryContact?.phone || '',
          };
          console.log('Facility:', facilityDetails);
          return of(facilityDetails);
        } else {
          return this.auth.getOrgData(this._as.get(AppVars.ORG_CODE)).pipe(
            map((orgData) => {
              const orgDetails = {
                facility: orgData.name,
                facilityAddress: orgData.address || '' + orgData.city || '' + orgData.state || '',
                facilityPhone: orgData.contactPhone || '',
              };
            })
          );
        }
      })
    );
  }

  private formatFacilityZipCode(zip: string): string {
    // if facility zip code is more than 5 characters, return first 5
    if (zip && zip.length > 5) {
      return zip.substring(0, 5);
    }
    return zip;
  }
}
