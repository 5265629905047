export class NameUtil {
  /**
   * Given a first and last name, returns the full name string.
   * If either argument is missing, returns the present argument.
   * If both arguments are missing, returns an empty string.
   */
  public static getFullName(firstName: string, lastName: string): string {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else if (firstName) {
      return firstName;
    } else if (lastName) {
      return lastName;
    } else {
      return '';
    }
  }

  /**
   * Formats a doctor's name as "lastname, firstname".
   * If the name contains a suffix, it is removed.
   * If the name contains a middle name, it is included with the first name.
   *
   * @param name - The full name of the doctor, potentially including a suffix.
   * @returns The formatted name as "lastname, firstname" or an empty string if the input is falsy.
   */

  public static getFormattedDoctorName(name: string): string {
    // format the doctor name as lastname, firstname by splitting the name
    if (!name) {
      return '';
    }
    const suffixRemoved = name.split(',')[0]; // This will split the name from the suffix
    let parts = suffixRemoved.trim().split(' ');
    parts.forEach((part) => part.trim());
    // remove any part that is empty from the parts array
    parts = parts.filter((part) => part !== '');
    // if parts length is 3, it means the name has a middle name, then use the first name & middle name/initial as the first name
    if (parts.length === 3) {
      return `${parts[2]}, ${parts[0]} ${parts[1]}`;
    } else if (parts.length === 2) {
      return `${parts[1]}, ${parts[0]}`;
    }
    return suffixRemoved.trim();
  }

  /**
   * Extracts the suffix from a doctor's full name.
   * Assumes the suffix is separated from the name by a comma.
   *
   * @param name - The full name of the doctor, including the suffix.
   * @returns The suffix as a string, or an empty string if the input is falsy or no suffix is found.
   */

  public static getDoctorSuffix(name: string): string {
    if (!name) {
      return '';
    }
    const parts = name.split(',');
    return parts[1].trim();
  }
}
